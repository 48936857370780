import * as React from 'react';

import { CompassThemeProvider } from '@circleci/compass';
import { NextPageContext } from 'next';
import { AppProps } from 'next/app';
import Head from 'next/head';

import isServerSideRendered from '~/utils/isServerSideRendered';
import '~/utils/realUserMonitoring'; // Initialize datadogRUM

interface Props extends AppProps {
  ctx: NextPageContext;
}

const WebUI404 = ({ Component, pageProps, router }: AppProps) => {
  return isServerSideRendered ? null : (
    <>
      <Head>
        <title>CircleCI</title>
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1'
        />
      </Head>
      <CompassThemeProvider>
        <Component
          {...pageProps}
          router={router}
        />
      </CompassThemeProvider>
    </>
  );
};

WebUI404.getInitialProps = async ({ Component, ctx }: Props) => {
  let pageProps = {};

  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
  }

  return { pageProps };
};

export default WebUI404;
